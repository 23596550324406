import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import "./news.scss";
import DefaultImage from "./default_news_image.jpg";
import * as s from "./News.module.scss";

export default function SimpleSlider() {
  const data = useStaticQuery(graphql`
    query AllNewsItems {
      allPrismicNews(sort: { order: DESC, fields: data___post_date }) {
        nodes {
          id
          uid
          data {
            summary
            post_date(formatString: "DD MMM yyyy")
            title {
              text
            }
            cover_image {
              url
            }
          }
        }
      }
    }
  `);

  const news = data.allPrismicNews.nodes || [];

  return (
    <section className="news wrapper bg-light">
      <div className="container py-14 py-md-16">
        <div className="news-grid">
          {news &&
            news.length > 0 &&
            news.map((item) => (
              <div
                className="item mb-4 col-xs-12 col-md-6 col-xl-4"
                key={item.id}
              >
                <article className={s.news_item}>
                  <figure className="overlay overlay-1 hover-scale">
                    <Link className="link-dark" to={`/news/${item.uid}`}>
                      <img
                        src={item.data.cover_image.url || DefaultImage}
                        alt=""
                      />
                    </Link>
                    <figcaption>
                      <h5 className="from-top mb-0">Read More</h5>
                    </figcaption>
                  </figure>
                  <div className="post-header">
                    <h2 className="post-title h3 mt-1 mb-3">
                      <Link className="link-dark" to={`/news/${item.uid}`}>
                        {item.data.title.text}
                      </Link>
                    </h2>
                    <p>{item.data.summary}</p>
                  </div>
                  <div className="post-footer">
                    <ul className="post-meta">
                      <li className="post-date">
                        <i className="uil uil-calendar-alt" />
                        <span>{item.data.post_date}</span>
                      </li>
                    </ul>
                  </div>
                </article>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
