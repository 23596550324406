import * as React from "react";
import Layout from "../../layout";
import News from "../../components/News/News";

const NewsPage = () => {
  return (
    <Layout
      title={"News"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
    >
      <News />
    </Layout>
  );
};

export default NewsPage;
